import React from 'react'
import {
  DeskSelectorContainer,
  DeskSelectorTitle,
  DeskCard,
  DeskCardContainer,
  Overlay,
  DeskCardTitle,
} from './InternationalDesk.styled'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
const DeskSelector = () => {
  let langs = [
    {
      url:
        'https://images.wallpaperscraft.com/image/road_skyscrapers_cars_121725_1366x768.jpg',
    },
    {
      url:
        'https://cdn-3.expansion.mx/dims4/default/b44ec00/2147483647/strip/true/crop/723x482+0+0/resize/800x533!/quality/90/?url=https%3A%2F%2Fcdn-3.expansion.mx%2F14%2F71%2Fc76c5dde432b91e6125238b0491b%2Fistock-1064307584.jpg',
    },
    {
      url: 'https://wallpapercave.com/wp/cmdkr6o.jpg',
    },
    {
      url: 'https://wallpapercave.com/wp/wp2324982.jpg',
    },
  ]
  return (
    <DeskSelectorContainer>
      <DeskSelectorTitle color="#fff" textAlign="center">
        <FormattedMessage id="desk.desk_selector.title" />
      </DeskSelectorTitle>
      <DeskCardContainer>
        {langs &&
          langs.map((lang, index) => (
            <DeskCard key={`deskSelector.lang${index}.key`} url={lang.url}>
              <Overlay>
                <DeskCardTitle>
                  <FormattedHTMLMessage
                    id={`deskSelector.lang${index}.title`}
                  />
                </DeskCardTitle>
              </Overlay>
            </DeskCard>
          ))}
      </DeskCardContainer>
    </DeskSelectorContainer>
  )
}

export default DeskSelector
