import React from 'react'
import { TwoColorContainer } from './InternationalDesk.styled'
import Chart from 'react-google-charts'

class Map extends React.Component {
  state = {
    data: [
      ['Country', 'Clientes satisfechos'],
      ['Mexico', 100],
      ['Spain', 200],
      ['United States', 300],
      ['Brazil', 100],
      ['France', 300],
    ],
    active: false,
  }

  render() {
    let { data } = this.state
    return (
      <TwoColorContainer>
        <div
          style={{ borderRadius: 40, overflow: 'hidden', position: 'relative' }}
        >
          <p
            style={{
              width: '100%',
              position: 'absolute',
              color: '#003163',
              top: -30,
              textAlign: 'center',
              zIndex: 5,
              fontWeight: 550,
              fontSize: '2.5rem',
              textShadow: '-1px 5px 0 rgba(0,0,0,.1)',
            }}
          >
            Un asesor especializado, no importa donde esté
          </p>
          <Chart
            width={'100%'}
            height={'70vh'}
            chartType="GeoChart"
            data={data}
            options={{
              backgroundColor: '#4286f4',
              colorAxis: {
                colors: ['#003163', '#003169'],
              },
              legend: 'none',
            }}
            mapsApiKey="AIzaSyDcLpXAm-7igc3YFHaGHph1Jyd7qgkHMtI"
            chartLanguage="ES"
          />
        </div>
      </TwoColorContainer>
    )
  }
}

export default Map
