import styled, { css } from 'styled-components'
import { BP_SMALL, BP_MEDIUM } from '../../styles/theme'

const WhyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
  margin: 1em;
`

const WhyCard = styled.div`
  height: 200px;
  width: 100%;
  border-radius: 10px;
  background-color: ${props => props.theme.backgroundBlueDark};
  position: relative;
  overflow: hidden;
`

const WhyImage = styled.img`
  width: 300px;
  position: absolute;
  top: 0;
  right: -150px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  ${WhyCard}:hover & {
    opacity: 0.3;
  }
  @media only screen and (max-width: ${BP_SMALL}) {
    opacity: 0.3;
    pointer-events: none;
  }
`

const WhyTitle = styled.p`
  margin: 5px 0;
  transform: translateY(200%);
  transition: all 0.5s ease-in-out;
  text-align: center;
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  ${WhyCard}:hover & {
    transform: translateY(10px);
  }
  @media only screen and (max-width: ${BP_SMALL}) {
    transform: translateY(10px);
    pointer-events: none;
  }
`

const WhyText = styled.p`
  position: absolute;
  padding-top: 8px;
  font-weight: 400;
  font-size: 0.8em;
  margin: 0 2em;
  color: #fff;
  opacity: 0;
  z-index: 10;
  transition: all 0.5s ease-in-out;
  ${WhyCard}:hover & {
    opacity: 1;
  }
  @media only screen and (max-width: ${BP_SMALL}) {
    opacity: 1;
    pointer-events: none;
  }
`

const DeskSelectorContainer = styled.div`
  background-color: ${props => props.theme.backgroundBlueDark};
  padding: 2%;
`

const DeskSelectorTitle = styled.h2`
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
`
const DeskCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 10px;
  margin: 1em 1em 5em 1em;
  @media only screen and (max-width: ${BP_SMALL}) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
`
const DeskCard = styled.div`
  width: 100%;
  height: 280px;
  background: url('${props => props.url}') center no-repeat;
  background-size: cover;
  display: flex;
`

const Overlay = styled.div`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.15);
  text-shadow: 1px 2px 3px ${props => props.theme.backgroundBlueDark};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`

const DeskCardTitle = styled.span`
  text-align: center;
  color: #fff;
  font-size: 2.5em;
  font-weight: 600;
`

const ResponsiveMap = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
`

const TwoColorContainer = styled.div`
  height: ${props => (props.height ? props.height : '70vh')};
  width: 100%;
  overflow: hidden;
  background: linear-gradient(
    to bottom,
    ${props => (props.top ? props.top : '#003163')} 0%,
    ${props => (props.top ? props.top : '#003163')} 50%,
    ${props => (props.bottom ? props.bottom : '#fff')} 0%,
    ${props => (props.bottom ? props.bottom : '#fff')} 50%
  );
`
const MapContainer = styled.div`
  background: url('https://www.gedeth.com/web/wp-content/uploads/2018/01/Mapamundi-basico.png')
    center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 40px;
`

const YoutubeCard = styled.a`
  border-radius: 7px;
  background-color: ${props => props.theme.backgroundBlueDark} !important;
  color: #fff;
  width: 50%;
  padding: 1em;
  margin-right: 2rem;
  margin-bottom: 2rem;
  display: flex;
  height: '100%';
  background-color: ${props => props.theme.backgroundLight};
  flex-direction: column;
  box-shadow: var(--shadow-dark);
  ${props =>
    props.horizontal &&
    css`
      max-width: 500px;
      flex-direction: row;
      height: 200px;
    `};
  @media only screen and (max-width: ${BP_MEDIUM}) {
    width: 95%;
  }
`
const TwoColumn = styled.div`
  display: flex;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    flex-wrap: wrap;
  }
`
const YoutubeTitle = styled.h4`
  text-align: center;
  margin-bottom: 0.5em;
`
const YoutubeDesc = styled.span`
  /* flex: 1; */
  margin: 2em 0 0 0;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
const YoutubeBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  margin: 16px;
`

const YoutubeThumbnail = styled.img`
  width: 100%;
  border-radius: 5px;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    margin: auto;
    width: 95%;
  }
`

const ContactFormBubble = styled.div`
  background: rgba(91, 160, 252);
  border-radius: 30px;
  width: 100%;
  padding: 30px 2rem 0;
  display: flex;
  flex-wrap: wrap;
  & > div {
    flex: 1;
  }
`

const ContactInfo = styled.div`
  color: #fff;
  & h2 {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 1rem;
  }
  & h4 {
    font-weight: 550;
    font-size: 1.6rem;
    margin: 0;
  }
  & p {
    margin: 0;
    font-weight: 350;
  }
`
export {
  WhyContainer,
  WhyCard,
  WhyImage,
  WhyTitle,
  WhyText,
  DeskSelectorContainer,
  DeskSelectorTitle,
  DeskCard,
  DeskCardContainer,
  Overlay,
  DeskCardTitle,
  ResponsiveMap,
  TwoColorContainer,
  MapContainer,
  YoutubeCard,
  YoutubeDesc,
  TwoColumn,
  YoutubeTitle,
  YoutubeBody,
  YoutubeThumbnail,
  ContactFormBubble,
  ContactInfo,
}
