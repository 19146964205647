import React from 'react'
import Button from '../common/Button.styled'
import { TitleCard } from '../common/Index.styled'
import { navigate } from 'gatsby'
import {
  WhyContainer,
  WhyCard,
  WhyImage,
  WhyTitle,
  WhyText,
} from './InternationalDesk.styled'
import { FormattedMessage } from 'react-intl'

const Why = () => {
  let reasons = [
    {
      key: 'experience',
      url:
        'http://clinicasopendental.com/clinicas/wp-content/uploads/2017/02/1647s-mano-png_LOGO_enfermedadesRaras.png',
    },
    {
      key: 'personalized_attention',
      url:
        'http://clinicasopendental.com/clinicas/wp-content/uploads/2017/02/1647s-mano-png_LOGO_enfermedadesRaras.png',
    },
    {
      key: 'solidity',
      url:
        'http://clinicasopendental.com/clinicas/wp-content/uploads/2017/02/1647s-mano-png_LOGO_enfermedadesRaras.png',
    },
    {
      key: 'efectiveness',
      url:
        'http://clinicasopendental.com/clinicas/wp-content/uploads/2017/02/1647s-mano-png_LOGO_enfermedadesRaras.png',
    },
    {
      key: 'warranty',
      url:
        'http://clinicasopendental.com/clinicas/wp-content/uploads/2017/02/1647s-mano-png_LOGO_enfermedadesRaras.png',
    },
  ]
  return (
    <div style={{ width: '100%' }}>
      <TitleCard textAlign="center">
        <FormattedMessage id="desk.section1.title" />
      </TitleCard>
      <WhyContainer>
        {reasons &&
          reasons.map((reason, index) => (
            <WhyCard key={reason.key}>
              <WhyTitle>
                <FormattedMessage id={`why.list.item${index}.title`} />
              </WhyTitle>
              <WhyText>
                <FormattedMessage id={`why.list.item${index}.description`} />
              </WhyText>
              <WhyImage src={reason.url} />
            </WhyCard>
          ))}
      </WhyContainer>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '4%' }}>
        <Button onClick={() => navigate('/#consulting-services')}>
          <FormattedMessage id="element.button_services_known" />
        </Button>
      </div>
    </div>
  )
}

export default Why
