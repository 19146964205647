import React from 'react'
import _times from 'lodash/times'

import { FormattedMessage } from 'react-intl'
import { TitleSection } from '../../common/Index.styled'
import Section from '../../common/Section'

const PageContentInternational = () => {
  return (
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.as-internacional.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedMessage id="solutions.as-internacional.content.p1" />
        </p>
        <h3>
          <FormattedMessage id="solutions.as-internacional.content.subtitle1" />
        </h3>
        <ul>
          {_times(12, String).map((value, key) => (
            <li key={key}>
              <FormattedMessage
                id={`solutions.as-internacional.content.list1.item${value}`}
              />
            </li>
          ))}
        </ul>
      </div>
    </Section>
  )
}

export default PageContentInternational
