export const contactSchema = [
  {
    type: 'string',
    name: 'name',
    label: 'Nombre',
    help: '',
    placeholder: '',
    required: true,
    validation: [],
    options: [],
  },
  {
    type: 'string',
    name: 'email',
    label: 'Email',
    help: '',
    placeholder: '',
    required: true,
    validation: [],
    options: [],
  },
  {
    type: 'string',
    name: 'phonenumber',
    label: 'Teléfono 10 digitos',
    help: '',
    placeholder: '',
    required: true,
    validation: [],
    options: [],
  },
  {
    type: 'textarea',
    name: 'message',
    label: 'Mensaje',
    help: '',
    placeholder: '',
    required: true,
    validation: [],
    options: [],
  },
]

export const susbcribeSchema = [
  {
    type: 'string',
    name: 'name',
    label: 'Nombre',
    help: '',
    placeholder: '',
    required: true,
    validation: [],
    options: [],
  },
  {
    type: 'string',
    name: 'email',
    label: 'Email',
    help: '',
    placeholder: '',
    required: true,
    validation: [],
    options: [],
  },
]

export const asConsultingValues = [
  'honesty',
  'responsability',
  'respect',
  'compromise',
  'felloweship',
  'improvment',
  'serve',
]
