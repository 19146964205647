import React from 'react'
import { navigate, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SectionImagen from '../components/SectionVideo/Imagen'
import Button from '../components/common/Button.styled'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import Why from '../components/InternationalDesk/Why'
import DeskSelector from '../components/InternationalDesk/DeskSelector'
import Map from '../components/InternationalDesk/Map'
import ContactForm from '../components/InternationalDesk/ContactForm'
import PageContentInternational from '../components/Solutions/Pages/International'

const internationalDesk = props => {
  const { file } = props.data

  return (
    <Layout title="International Desk" description="Algo del desk" {...props}>
      <SectionImagen
        fluid={file.childImageSharp.fluid}
        objectFit="cover"
        alt="Constituir Empresa"
      >
        <h1>
          <FormattedMessage id="desk.header.title" />
        </h1>
        <p>
          <FormattedHTMLMessage id="desk.header.description" />
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => navigate('/contacto/')}>
            <FormattedMessage id="element.button_register_free" />
          </Button>
        </div>
      </SectionImagen>
      <Why />
      <DeskSelector />
      <Map />
      <PageContentInternational />
      <ContactForm />
    </Layout>
  )
}

export default internationalDesk

export const pageQuery = graphql`
  query InternatinalQuery {
    file(relativePath: { eq: "mexicobandera.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
