import React from 'react'
import {
  TwoColorContainer,
  ContactFormBubble,
  ContactInfo,
} from './InternationalDesk.styled'
import FormFields from '../common/FormFields'
import { contactSchema } from '../../utils/schemas'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import Icon from '../common/Icon'
import { createNotification } from '../../utils/createNotification'
import PropTypes from 'prop-types'
import axios from 'axios'

class ContactForm extends React.Component {
  static propTypes = {
    actionAfterSubmit: PropTypes.func,
  }

  static defaultProps = {
    actionAfterSubmit: () => {},
  }
  state = {
    loading: false,
    clearValues: false,
  }

  handleSubmit = form => {
    this.setState({ loading: true }, async () => {
      try {
        await axios({
          method: 'post',
          baseURL: process.env.API_CONTACT_BASE_URL,
          url: '/submit',
          data: { ...form },
          auth: {
            username: 'ASConsulting_Group',
            password: process.env.API_PASSWORD_SEND_EMAIL,
          },
        })
        createNotification('success', {
          title: 'Enviado correctamente!',
          message: '¡Se ha enviado con exito!',
        })
        this.setState(
          {
            loading: false,
            clearValues: !this.state.clearValues,
          },
          () => this.props.actionAfterSubmit()
        )
      } catch (err) {
        console.log('Error', err)
        createNotification('error', {
          title: 'Hubo un error',
          message: err.message,
        })
        this.setState({ loading: false })
      }
    })
  }
  render() {
    let contactData = [
      {
        icon: 'at',
      },
      {
        icon: 'phone',
      },
      {
        icon: 'map-marker-alt',
      },
    ]

    return (
      <TwoColorContainer top="#fff" bottom="#000" height="auto">
        <ContactFormBubble>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <FormFields
              submit={this.handleSubmit}
              styles={{
                backgroundColor: 'white',
                padding: '2rem',
                borderRadius: '10px 10px 0 0',
              }}
              fields={contactSchema}
              buttonText="button_send"
              clearValues={this.state.clearValues}
              color="#000"
            />
          </div>
          <ContactInfo>
            <h2>Contacto</h2>
            {contactData.map((item, index) => (
              <div key={index}>
                <h4>
                  <Icon
                    icon={item.icon}
                    size="lg"
                    style={{ paddingRight: 10 }}
                  />
                  <FormattedMessage id={`desk.contact.item${index}.label`} />
                </h4>
                <p>
                  <FormattedHTMLMessage id={`desk.contact.item${index}.info`} />
                </p>
              </div>
            ))}
          </ContactInfo>
        </ContactFormBubble>
      </TwoColorContainer>
    )
  }
}

export default ContactForm
